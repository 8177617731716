import { SIDE_BAR_WIDTH } from '@/constants/css.constants';
import { CustomRoute, RouteCategory } from '@/routers/routes';
import Logo from '@components/commons/Logo';
import MenuItem from '@components/commons/SideBar/menuItem';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  List,
  Stack,
  Theme,
  styled,
} from '@mui/material';
import { CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { ScreenWidth } from '@enums/screen.enum';
import { retrieveFromStorage } from '@utils/storage.util';

interface ComponentProps {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  menuItems: CustomRoute[];
  categoryItems: RouteCategory[];
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDE_BAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up(
    retrieveFromStorage('mobileView') === 'true'
      ? ScreenWidth.MEDIUM
      : ScreenWidth.SMALL
  )]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideBar = (props: ComponentProps) => {
  const {
    open,
    handleDrawerClose,
    handleDrawerOpen,
    menuItems,
    categoryItems,
  } = props;

  return (
    <>
      <Drawer
        key={'menu'}
        variant='permanent'
        hideBackdrop
        // disableEnforceFocus
        // disableScrollLock
        anchor={'left'}
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.default,
            boxShadow: 'none',
            borderRight: '1px solid',
            borderColor: (theme) => theme.palette.divider,
            minWidth: 0,
          },
        }}
      >
        <Box
          sx={{
            minHeight: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant='text'
            sx={{
              color: 'text.primary',
              width: '100%',
              height: '100%',
              p: 0,
            }}
            onClick={handleDrawerClose}
          >
            <Stack
              sx={{ width: '100%', m: (theme) => theme.spacing(0, 2.5) }}
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              spacing={0.5}
            >
              <ArrowBack sx={{ color: 'primary.dark' }} />
              <Logo variant='black' />
            </Stack>
          </Button>
        </Box>
        <Divider />
        <List component='nav' sx={{ m: (theme) => theme.spacing(0, 1) }}>
          {categoryItems.map((category) => (
            <MenuItem
              key={`${category.name}`}
              category={category}
              subItems={menuItems}
              open={open}
              handleDrawerOpen={handleDrawerOpen}
            />
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
