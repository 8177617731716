import React from 'react';
import useIosInstallPrompt from '@/hooks/useIosInstallPrompt';
import useWebInstallPrompt from '@/hooks/useWebInstallPrompt';
import Button from '@mui/material/Button';
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IosShareSharp } from '@mui/icons-material';
import { theme } from '@/themes/MolunderTheme';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { I18N } from '@/enums/i18n.enum';
import Logo from '@components/commons/Logo';
import { ScreenWidth } from '@enums/screen.enum';
import { retrieveFromStorage } from '@utils/storage.util';
export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const isWeb = useMediaQuery(
    theme.breakpoints.up(
      retrieveFromStorage('mobileView') === 'true'
        ? ScreenWidth.MEDIUM
        : ScreenWidth.SMALL
    )
  );
  const { t } = useTranslation();
  const history = useHistory();
  const {
    BUTTONS_CANCEL,
    BUTTONS_INSTALL,
    BUTTONS_OK,
    PROMPTS_INSTALL_TITLE,
    PROMPTS_INSTALL_DESCRIPTION,
    PROMPTS_INSTALL_DESCRIPTION_IOS,
  } = I18N;

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <>
      {history.location.pathname !== '/' && iosInstallPrompt && (
        <Card
          sx={{
            position: 'fixed',
            transform: isWeb ? 'none' : 'translateX(50%)',
            zIndex: 999999,
            top: (theme) => theme.spacing(12),
            right: isWeb ? (theme) => theme.spacing(4) : '50%',
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          <CardContent>
            <Stack alignItems='center' justifyContent='center'>
              <Avatar
                sx={{
                  bgcolor: (theme) => theme.palette.primary.dark,
                  p: (theme) => theme.spacing(4),
                }}
                variant='rounded'
              >
                <Typography variant='subtitle1' sx={{ fontSize: 12 }}>
                  <Logo disableText sxImg={{ height: '4rem', width: 'auto' }} />
                </Typography>
              </Avatar>
              <Typography
                variant='h5'
                sx={{ width: isWeb ? 300 : '100%', textAlign: 'center', mt: 2 }}
              >
                {t(PROMPTS_INSTALL_TITLE)}
              </Typography>
              <Typography
                variant='body2'
                sx={{ width: isWeb ? 300 : '90%', textAlign: 'center', mt: 2 }}
              >
                {t(PROMPTS_INSTALL_DESCRIPTION_IOS)}
              </Typography>
              <IosShareSharp
                sx={{ mt: (theme) => theme.spacing(2), fontSize: 32 }}
              />
              <Stack
                mt={4}
                sx={{ width: '80%' }}
                spacing={2}
                direction='row'
                justifyContent='space-between'
                alignItems='space-between'
              >
                <Button
                  fullWidth
                  variant='contained'
                  onClick={handleIOSInstallDeclined}
                >
                  {t(BUTTONS_OK)}
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}

      {history.location.pathname !== '/' && webInstallPrompt && (
        <Card
          sx={{
            position: 'fixed',
            transform: isWeb ? 'none' : 'translateX(50%)',
            zIndex: 999999,
            top: (theme) => theme.spacing(12),
            right: isWeb ? (theme) => theme.spacing(4) : '50%',
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, 0.12), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          <CardContent>
            <Stack alignItems='center' justifyContent='center'>
              <Avatar
                sx={{
                  bgcolor: (theme) => theme.palette.primary.dark,
                  p: (theme) => theme.spacing(4),
                }}
                variant='rounded'
              >
                <Typography variant='subtitle1' sx={{ fontSize: 12 }}>
                  <Logo disableText />
                </Typography>
              </Avatar>
              <Typography
                variant='h5'
                sx={{ width: isWeb ? 300 : '100%', textAlign: 'center', mt: 2 }}
              >
                {t(PROMPTS_INSTALL_TITLE)}
              </Typography>
              <Typography
                variant='body2'
                sx={{ width: isWeb ? 300 : '90%', textAlign: 'center', mt: 2 }}
              >
                {t(PROMPTS_INSTALL_DESCRIPTION)}
              </Typography>
              <Stack
                mt={2}
                sx={{ width: '80%' }}
                spacing={2}
                direction='row'
                justifyContent='space-between'
                alignItems='space-between'
              >
                <Button
                  fullWidth
                  variant='outlined'
                  onClick={handleWebInstallDeclined}
                >
                  {t(BUTTONS_CANCEL)}
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={handleWebInstallAccepted}
                >
                  {t(BUTTONS_INSTALL)}
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
};
