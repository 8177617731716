import { Stack, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect } from 'react';
import LogoHorizontal from '../../../assets/images/norway/logo-horizontal.svg';
import LogoWhiteHorizontal from '../../../assets/images/norway/logo-horizontal-white.svg';
import LogoVertical from '../../../assets/images/norway/logo-vertical.svg';
import LogoWhiteVertical from '../../../assets/images/norway/logo-vertical-white.svg';
import LogoVerticalNoText from '../../../assets/images/norway/logo-vertical-notext.svg';
interface ComponentProps {
  direction?: 'horizontal' | 'vertical';
  variant?: 'white' | 'black';
  height?: number;
  disableText?: boolean;
  sx?: SxProps<Theme>;
  goHome?: () => void;
  sxImg?: React.CSSProperties;
}
const Logo = (props: ComponentProps) => {
  const {
    direction = 'horizontal',
    variant = 'white',
    height = 64,
    disableText,
    sx,
    goHome,
    sxImg,
  } = props;
  //const isWeb = useMediaQuery(theme.breakpoints.up(retrieveFromStorage('mobileView') === 'true' ? ScreenWidth.MEDIUM : ScreenWidth.SMALL));
  const [logo, setLogo] = React.useState<string>();

  useEffect(() => {
    switch (variant) {
      case 'white':
        direction === 'horizontal'
          ? setLogo(LogoWhiteHorizontal)
          : setLogo(LogoWhiteVertical);
        break;
      case 'black':
        direction === 'horizontal'
          ? setLogo(LogoHorizontal)
          : setLogo(LogoVertical);
        break;
    }
    if (disableText) {
      setLogo(LogoVerticalNoText);
    }
  }, []);

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{ height: height, ...sx }}
      onClick={() => {
        if (goHome) {
          goHome();
        }
      }}
    >
      <img
        style={{
          width: '10rem',
          height: '100%',
          ...sxImg,
        }}
        src={logo}
        alt='logo'
      />
    </Stack>
  );
};

export default Logo;
