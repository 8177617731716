import {
  BOTTOM_NAVIGATION_HEIGHT,
  SIDE_BAR_WIDTH,
  TOOLBAR_HEIGHT,
} from '@/constants/css.constants';
import { I18N } from '@/enums/i18n.enum';
import { MAP_TYPE } from '@/enums/map.enum';
import { USER_ROLE } from '@/enums/user.enum';
import { MenuNames } from '@/routers/RouteCategoryName.enum';
import { CustomRoute, guardRoutes, routeCategories } from '@/routers/routes';
import { theme } from '@/themes/MolunderTheme';
import BottomNavigationBar from '@components/commons/BottomNavigationBar';
import NavigationBar from '@components/commons/NavigationBar';
import SideBar from '@components/commons/SideBar';
import MarketPlaceFilter from '@components/filter';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { isMatchPath } from '@utils/url.utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
//import { InstallPWA } from '@components/commons/InstallPwa';
import { useStore } from '@/RootStoreProvider';
import useIframeState from '@hooks/useIframeState';
import { PackRule } from '@casl/ability/extra';
import { Action } from '@enums/casl.enum';
import { clone } from '@utils/object.utils';
import { ScreenWidth } from '@enums/screen.enum';
import { retrieveFromStorage } from '@utils/storage.util';

const GuardLayout = () => {
  const { authenticationStore, commonStore, massStore, notificationStore } =
    useStore();
  const history = useHistory();
  const { isSidebarOpen, isFilterOpen, lastMapTypeOpen } = commonStore;
  const setSidebarOpen = (toggle: boolean): void => {
    commonStore.setSidebarOpen(toggle);
  };
  const isInIframe = useIframeState();
  const setFilterOpen = (toggle: boolean): void => {
    commonStore.setFilterOpen(toggle);
  };
  const isWeb = useMediaQuery(
    theme.breakpoints.up(
      retrieveFromStorage('mobileView') === 'true'
        ? ScreenWidth.MEDIUM
        : ScreenWidth.SMALL
    )
  );
  const userRole = authenticationStore.loggedUser?.role as USER_ROLE;
  const userRulesByModule = authenticationStore.loggedUser?.rulesByModule;

  const { t } = useTranslation();
  const {
    MENU_REQUEST,
    MENU_IN_PROGRESS,
    MENU_COMPLETED,
    MENU_REPORTING,
    MENU_MATERIALS,
    MENU_LOCATIONS,
    MENU_MARKETPLACE,
    MENU_CO2_EMISSION,
    MENU_TRANSPORT,
    MENU_OFFER,
    MENU_DATA_EXTRACTION,
    MENU_MY_PROJECTS,
    MENU_MY_STORAGES,
    MENU_PROJECTS,
    MENU_COMPANIES,
    MENU_EMISSIONS_CALCULATOR,
    MENU_USERS,
    MENU_DECLARATIONS,
    MENU_CUSTOMERS,
    MENU_COMPANY,
    MENU_RESOURCES,
  } = I18N;

  const accessibleRoutes = guardRoutes.filter((route: CustomRoute) => {
    if (!authenticationStore.loggedUser.companyId) {
      return true;
    }
    const requiredModule = route.modules ? route.modules[0] : null;
    const requiredAction = route.action as string;
    if (!requiredModule || userRole === USER_ROLE.ADMIN) {
      return true;
    }
    if (
      !userRulesByModule.some((module) => module.hasOwnProperty(requiredModule))
    ) {
      return false;
    }
    if (!requiredAction) {
      return true;
    }
    const allowedActions = userRulesByModule
      .find((module) => module.hasOwnProperty(requiredModule))
      [requiredModule].map((rule: PackRule<any>) => rule[0]);
    return allowedActions.some((action) =>
      [requiredAction, Action.Manage].includes(action)
    );
  });

  const menuItems = accessibleRoutes
    .filter((route: CustomRoute) =>
      route.userRoles
        ? route.isMenu && route.userRoles?.includes(userRole)
        : route.isMenu
    )
    .map((route: CustomRoute) => {
      switch (route.name) {
        case MenuNames.HOME_EXCHANGE:
          route.title = t(MENU_MARKETPLACE);
          break;
        case MenuNames.HOME_SUPPLY:
          route.title = t(MENU_OFFER);
          break;
        case MenuNames.HOME_REQUEST:
          route.title = t(MENU_REQUEST);
          break;
        case MenuNames.REGISTER_SUPPLY:
          route.title = t(MENU_OFFER);
          break;
        case MenuNames.REGISTER_REQUEST:
          route.title = t(MENU_REQUEST);
          break;
        case MenuNames.MY_PROJECTS:
          route.title = t(MENU_MY_PROJECTS);
          break;
        case MenuNames.MY_STORAGES:
          route.title = t(MENU_MY_STORAGES);
          break;
        case MenuNames.TRANSACTION_IN_PROGRESS:
          route.title = t(MENU_IN_PROGRESS);
          break;
        case MenuNames.TRANSACTION_COMPLETED:
          route.title = t(MENU_COMPLETED);
          break;
        case MenuNames.ANALYSIS_REPORT:
          route.title = t(MENU_REPORTING);
          break;
        case MenuNames.ANALYSIS_DATA_EXTRACTION:
          route.title = t(MENU_DATA_EXTRACTION);
          break;
        case MenuNames.SETTING_MATERIAL:
          route.title = t(MENU_MATERIALS);
          break;
        case MenuNames.SETTING_HUB:
          route.title = t(MENU_LOCATIONS);
          break;
        case MenuNames.SETTING_CO2EMISSION:
          route.title = t(MENU_CO2_EMISSION);
          break;
        case MenuNames.SETTING_TRANSPORT:
          route.title = t(MENU_TRANSPORT);
          break;
        case MenuNames.SETTING_PROJECTS:
          route.title = t(MENU_PROJECTS);
          break;
        case MenuNames.SETTING_COMPANIES:
          route.title = t(MENU_COMPANIES);
          break;
        case MenuNames.SETTING_EMISSIONS_CALCULATOR:
          route.title = t(MENU_EMISSIONS_CALCULATOR);
          break;
        case MenuNames.SETTING_USERS:
          route.title = t(MENU_USERS);
          break;
        case MenuNames.DECLARATION_OVERVIEW:
          route.title = t(MENU_DECLARATIONS);
          break;
        case MenuNames.DECLARATION_CUSTOMER:
          route.title = t(MENU_CUSTOMERS);
          break;
        case MenuNames.DECLARATION_RESOURCES:
          route.title = t(MENU_RESOURCES);
          break;
        case MenuNames.SETTING_COMPANY:
          route.title = t(MENU_COMPANY);
          break;
      }
      return route;
    });
  const guardedRoutes = accessibleRoutes.filter((route: CustomRoute) =>
    route.userRoles
      ? !route.isLayout && route.userRoles?.includes(userRole)
      : !route.isLayout
  );
  const categoriesAvailable = menuItems.map((menu) => menu.category);
  const categoryItems = routeCategories;
  const mobileMenuItems = guardRoutes.filter((route: CustomRoute) =>
    route.userRoles
      ? route.isMobileMenu && route.userRoles?.includes(userRole)
      : route.isMobileMenu
  );

  const logout = async (): Promise<void> => {
    await authenticationStore.logout(history);
    notificationStore.setNotificationsInit();
  };

  const handleDrawerOpen = (): void => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = (): void => {
    setSidebarOpen(false);
  };

  const handleFilterDrawerClose = (): void => {
    setFilterOpen(false);
  };

  const isEditRegisterSupply = (): boolean => {
    return isMatchPath('/register/supply/:id');
  };

  const isEditRegisterRequest = (): boolean => {
    return isMatchPath('/register/request/:id');
  };
  const isHomeSupplyInfo = (): boolean => {
    return isMatchPath('/home/supply/:id');
  };

  const isHomeRequestInfo = (): boolean => {
    return isMatchPath('/home/request/:id');
  };
  const isHomeRequest = (): boolean => {
    return isMatchPath('/home/request');
  };

  const isHomeSupply = (): boolean => {
    return isMatchPath('/home/supply');
  };

  useEffect(() => {
    commonStore.setSidebarOpen(isWeb);
    if (isFilterOpen) commonStore.setSidebarOpen(false);
    if (isInIframe) commonStore.setSidebarOpen(false);
  }, [commonStore, isWeb, isFilterOpen, isInIframe]);

  const isHideNavigationBar =
    (!isWeb && isEditRegisterSupply() && massStore.isUpdateSupply) ||
    (!isWeb && isEditRegisterRequest() && massStore.isUpdateRequest) ||
    (!isWeb && isHomeSupplyInfo() && massStore.transactionFormIsOpen) ||
    (!isWeb && isHomeRequestInfo() && massStore.transactionFormIsOpen);

  const isTransparent = isHomeRequest() || isHomeSupply();

  const userVisitedAsGuest = authenticationStore.loggedUser?.visitedAsGuest;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: (theme) => theme.palette.background.container,
        }}
      >
        <CssBaseline />
        {!isHideNavigationBar && (
          <NavigationBar
            isWeb={isWeb}
            logout={logout}
            openLeft={isSidebarOpen}
            openRight={isFilterOpen}
            handleDrawerMenuClick={() => setSidebarOpen(!isSidebarOpen)}
            isTransparent={isTransparent}
          />
        )}
        {isFilterOpen && (
          <MarketPlaceFilter
            open={isFilterOpen}
            handleDrawerClose={handleFilterDrawerClose}
            asNotification={false}
            fromSupply={lastMapTypeOpen == MAP_TYPE.SUPPLY ? true : false}
          />
        )}
        {!userVisitedAsGuest && (
          <>
            {isWeb ? (
              <SideBar
                open={isSidebarOpen}
                handleDrawerClose={handleDrawerClose}
                handleDrawerOpen={handleDrawerOpen}
                menuItems={menuItems}
                categoryItems={categoryItems}
              />
            ) : (
              !isFilterOpen && (
                <Box sx={{ zIndex: 9 }}>
                  <BottomNavigationBar
                    categoryItems={categoryItems}
                    menuItems={mobileMenuItems}
                  />
                </Box>
              )
            )}
          </>
        )}
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            mt:
              !isHideNavigationBar && !isTransparent
                ? `${TOOLBAR_HEIGHT}px`
                : 0,
            mb: !isWeb ? `${BOTTOM_NAVIGATION_HEIGHT}px` : 0,
          }}
        >
          <Switch>
            {guardedRoutes.map((item: CustomRoute) => (
              <Route
                key={item.path}
                path={item.path}
                component={item.component}
                exact={item.exact}
              />
            ))}
            <Redirect to='/my-projects' />
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default observer(GuardLayout);
