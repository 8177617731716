import http from '@/apis';
import { InfoAsset } from '@/dto/asset/InfoAsset.dto';
import {
  PaginationRequest,
  PaginationResult,
} from '@/dto/commons/PaginationRequest.dto';
import { ActivitiesResult } from '@/dto/mass/activities/ActivitiesResult.dto';
import { RegisteredPagination } from '@/dto/mass/activities/paginations/RegisteredPagination.dto';
import { TransactionPagination } from '@/dto/mass/activities/paginations/TransactionPagination.dto';
import { RegisterRequestDto } from '@/dto/mass/request/RegisterRequest.dto';
import { Request } from '@/dto/mass/request/Request.dto';
import { SearchRequest } from '@/dto/mass/request/SearchRequest.dto';
import { CreateUpdateTransaction } from '@/dto/mass/reservation/Reservation.dto';
import { SearchMass } from '@/dto/mass/searchMass.dto';
import { RegisterSupplyDto } from '@/dto/mass/supply/RegisterSupply.dto';
import { SearchSupply } from '@/dto/mass/supply/SearchSupply.dto';
import { Supply } from '@/dto/mass/supply/Supply.dto';
import {
  ACTIVITIES_RESULT_STATUS,
  ACTIVITIES_RESULT_TYPE,
  MASS_TRANSACTION_TYPES,
} from '@/enums/mass.enum';
import { prepareGetQuery } from '@utils/api.utils';
import { SettingsMaterialApiTranslation } from '@/dto/settings/SettingsMaterial.dto';
import { filterByLanguage } from '@utils/language.utils';
import { SearchExchange } from '@/dto/mass/exchange/SearchExchange.dto';
import { ShareMass } from '@dto/mass/share/ShareMass.dto';

class MassService {
  prefix = 'mass';
  transactionPrefix = 'transaction';

  public async getAllSupplyMinify(): Promise<Supply[]> {
    const result = await http.get(`${this.prefix}/supply/all`);
    return result.data.result;
  }

  public async getAllRequestMinify(): Promise<Request[]> {
    const result = await http.get(`${this.prefix}/request/all`);
    return result.data.result;
  }

  public async getAllExchangeMinify(): Promise<(Request | Supply)[]> {
    const result = await http.get(`${this.prefix}/exchange/all`);
    return result.data.result;
  }

  public async registerSupply(model: RegisterSupplyDto): Promise<boolean> {
    const result = await http.post(`${this.prefix}/supply`, model);
    return result.data.result;
  }

  public async registerRequest(model: RegisterRequestDto): Promise<boolean> {
    const result = await http.post(`${this.prefix}/request`, model);
    return result.data.result;
  }

  public async getSupplyByUserId(
    model: PaginationRequest
  ): Promise<PaginationResult<Supply>> {
    const result = await http.get(
      `${this.prefix}/supply/${prepareGetQuery(model)}`
    );
    return result.data.result;
  }

  public async getAllSupply(): Promise<Supply[]> {
    const result = await http.get(`${this.prefix}/supply/`);
    return result.data.result.data;
  }

  public async getAllRequest(): Promise<Request[]> {
    const result = await http.get(`${this.prefix}/request/`);
    return result.data.result.data;
  }

  //TODO: Get correct API endpoints and merge data
  public async getActivitiesByCompanyId(id: number): Promise<any> {
    const requests = await http.get(`${this.prefix}/request/${id}/`);
    const supply = await http.get(`${this.prefix}/supply/${id}/`);
    const result = {
      ...requests.data.result.data,
      ...supply.data.result.data,
    };
    return result;
  }

  public async getRequestByUserId(
    model: PaginationRequest
  ): Promise<PaginationResult<Request>> {
    const result = await http.get(
      `${this.prefix}/request/${prepareGetQuery(model)}`
    );
    return result.data.result;
  }

  public async getSupplyInfo(id: number): Promise<Supply> {
    const result = await http.get(`${this.prefix}/supply/${id}`);
    const supply = result.data.result;
    const translations = filterByLanguage(
      supply.translations
    ) as SettingsMaterialApiTranslation[];
    return { ...supply, translations, massAssets: supply.massSupplyAssets };
  }

  public async getRequestInfo(id: number): Promise<Request> {
    const result = await http.get(`${this.prefix}/request/${id}`);
    const request = result.data.result;
    const translations = filterByLanguage(
      request.translations
    ) as SettingsMaterialApiTranslation[];
    return { ...request, translations, massAssets: request.massRequestAssets };
  }

  public async updateSupplyStatus(
    id: number,
    status: ACTIVITIES_RESULT_STATUS
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/supply/status/${id}`, {
      status,
    });
    return result.data.result;
  }

  public async updateRequestStatus(
    id: number,
    status: ACTIVITIES_RESULT_STATUS
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/request/status/${id}`, {
      status,
    });
    return result.data.result;
  }

  public async updateSupplyNotification(
    id: number,
    receiveNotifications: boolean
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/supply/notification/${id}`, {
      receiveNotifications,
    });
    return result.data.result;
  }

  public async updateRequestNotification(
    id: number,
    receiveNotifications: boolean
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/request/notification/${id}`, {
      receiveNotifications,
    });
    return result.data.result;
  }

  public async updateSupply(
    id: number,
    model: RegisterSupplyDto
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/supply/${id}`, model);
    return result.data.result;
  }

  public async updateRequest(
    id: number,
    model: RegisterRequestDto
  ): Promise<boolean> {
    const result = await http.put(`${this.prefix}/request/${id}`, model);
    return result.data.result;
  }

  public async getMinifiedSupply(model: SearchMass): Promise<Supply[]> {
    const result = await http.get(
      `${this.prefix}/supply/minified/${prepareGetQuery(model)}`
    );
    return result.data.result;
  }

  public async searchSupply(model: SearchSupply): Promise<Supply[]> {
    const result = await http.get(
      `${this.prefix}/search/supply/${prepareGetQuery(model)}`
    );

    const supplies = result.data.result;

    const suppliesTranslation = supplies.map((supply: Supply) => {
      const translations = filterByLanguage(
        supply.translations
      ) as SettingsMaterialApiTranslation[];
      return { ...supply, translations };
    });

    return suppliesTranslation;
  }

  public async getMinifiedRequest(model: SearchMass): Promise<Request[]> {
    const result = await http.get(
      `${this.prefix}/request/minified/${prepareGetQuery(model)}`
    );
    return result.data.result;
  }

  public async getMinifiedExchange(
    model: SearchMass
  ): Promise<(Request | Supply)[]> {
    const result = await http.get(
      `${this.prefix}/exchange/minified/${prepareGetQuery(model)}`
    );
    return result.data.result;
  }

  public async searchRequest(model: SearchRequest): Promise<Request[]> {
    const result = await http.get(
      `${this.prefix}/search/request/${prepareGetQuery(model)}`
    );

    const requests = result.data.result;

    const requestsTranslation = requests.map((request: Request) => {
      const translations = filterByLanguage(
        request.translations
      ) as SettingsMaterialApiTranslation[];
      return { ...request, translations };
    });

    return requestsTranslation;
  }

  public async searchExchange(
    model: SearchExchange
  ): Promise<(Request | Supply)[]> {
    const result = await http.get(
      `${this.prefix}/search/exchange/${prepareGetQuery(model)}`
    );

    const requests = result.data.result;

    const requestsTranslation = requests.map((request: Request) => {
      const translations = filterByLanguage(
        request.translations
      ) as SettingsMaterialApiTranslation[];
      return { ...request, translations };
    });

    return requestsTranslation;
  }

  public async createMassReservation(
    model: CreateUpdateTransaction
  ): Promise<boolean> {
    const result = await http.post(`${this.transactionPrefix}`, model);
    return result.data.result;
  }

  public async createMassOffer(
    model: CreateUpdateTransaction
  ): Promise<boolean> {
    const result = await http.post(`${this.transactionPrefix}`, model);
    return result.data.result;
  }

  public async getActivityRegisteredList(
    model: RegisteredPagination
  ): Promise<PaginationResult<ActivitiesResult>> {
    const result = await http.get(
      `${this.prefix}/activities/registered${prepareGetQuery(
        model as Record<string, unknown>
      )}`
    );
    return result.data.result;
  }

  public async getActivityPlannedList(
    model: TransactionPagination
  ): Promise<PaginationResult<ActivitiesResult>> {
    const result = await http.get(
      `${this.prefix}/activities/planned${prepareGetQuery(
        model as Record<string, unknown>
      )}`
    );
    return result.data.result;
  }

  public async getActivityCompletedList(
    model: TransactionPagination
  ): Promise<PaginationResult<ActivitiesResult>> {
    const result = await http.get(
      `${this.prefix}/activities/completed${prepareGetQuery(
        model as Record<string, unknown>
      )}`
    );
    return result.data.result;
  }

  public async getRelatedTransactions(
    massId: number,
    model?: TransactionPagination
  ): Promise<PaginationResult<ActivitiesResult>> {
    const result = await http.get(
      `${this.prefix}/activities/related/${massId}${prepareGetQuery(
        model as Record<string, unknown>
      )}`
    );
    return result.data.result;
  }

  public async updateMassReservation(
    id: number,
    volume: number,
    transportDate: Date
  ): Promise<boolean> {
    const result = await http.put(
      `${this.transactionPrefix}/reservation/${id}`,
      {
        volume,
        transportDate,
        transactionType: MASS_TRANSACTION_TYPES.RESERVATION,
      }
    );
    return result.data.result;
  }

  public async updateMassOffer(
    id: number,
    volume: number,
    transportDate: Date
  ): Promise<boolean> {
    const result = await http.put(`${this.transactionPrefix}/offer/${id}`, {
      volume,
      transportDate,
      transactionType: MASS_TRANSACTION_TYPES.OFFER,
    });
    return result.data.result;
  }

  public async deleteMassReservation(id: number): Promise<boolean> {
    const result = await http.delete(
      `${this.transactionPrefix}/reservation/${id}`
    );
    return result.data.result;
  }

  public async deleteMassOffer(id: number): Promise<boolean> {
    const result = await http.delete(`${this.transactionPrefix}/offer/${id}`);
    return result.data.result;
  }

  public async registerTrip(
    id: number,
    type: ACTIVITIES_RESULT_TYPE,
    volume: number
  ): Promise<boolean> {
    const result = await http.post(`${this.prefix}/trip`, {
      id,
      type,
      volume,
    });

    return result.data.result;
  }

  public async getViewMassTransaction(
    id: number,
    type: ACTIVITIES_RESULT_TYPE
  ): Promise<ActivitiesResult> {
    const result = await http.get(`${this.prefix}/activities/detail/${id}`, {
      params: {
        type,
      },
    });
    return result.data.result;
  }

  public async getMassByProjectId(
    projectId: number
  ): Promise<Array<Supply | Request>> {
    const result = await http.get(`${this.prefix}/project/${projectId}`);
    return result.data.result;
  }

  public async getTransactionByMassId(
    massId: number,
    isSupply: boolean
  ): Promise<CreateUpdateTransaction[]> {
    const result = await http.get(
      `${this.transactionPrefix}/${isSupply ? 'supply' : 'request'}/${massId}`
    );
    return result.data.result;
  }

  public async shareMassLink(model: ShareMass): Promise<boolean> {
    const result = await http.post(`${this.prefix}/share`, model);
    return result.data.result;
  }
}

export default new MassService();
