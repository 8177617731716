import { CreateUpdateProject } from '@/dto/project/Project.dto';
import { ProjectMaterial } from '@/dto/project/ProjectMaterial.dto';
import { INIT_LOCATION } from './location.constants';
import { addDays } from '@utils/date.utils';
import {
  PROJECT_STATUS,
  ProjectMassType,
  ProjectSupplyDemandType,
} from '@enums/project.enum';
import { I18N } from '@enums/i18n.enum';
import { ProjectMass } from '@dto/project/ProjectMass.dto';

export const PROJECT_MATERIAL_LEVEL = {
  MAIN: 1,
  SECONDARY: 2,
};

export const INIT_PROJECT_MATERIAL: ProjectMaterial = {
  materialId: 0,
  materialLevel: 1,
};

export const INIT_PROJECT: CreateUpdateProject = {
  id: 0,
  name: '',
  location: INIT_LOCATION,
  description: '',
  activePeriodStart: new Date(),
  activePeriodEnd: addDays(new Date(), 30),
  projectMaterials: [],
  massSupplies: [],
  massRequests: [],
  associations: [],
  owner: null,
  projectMasses: [],
  operatingHourStart: null,
  operatingHourEnd: null,
};

export const INIT_PROJECT_FORM_ERROR: Record<string, boolean> = {
  name: false,
  description: false,
  activePeriodStart: false,
  activePeriodEnd: false,
  location: false,
};

export const PROJECT_KEY_PREFIX = 'project_';
export const PROJECT_MASS_KEY_PREFIX = 'project_mass';
export const PROJECT_TRIP_KEY_PREFIX = 'project_trip';

export const INIT_PROJECT_STATUSES = [
  {
    value: PROJECT_STATUS.PLANNED,
    label: I18N.STATUS_PROJECT_PLANNED,
  },
  {
    value: PROJECT_STATUS.ACTIVE,
    label: I18N.STATUS_PROJECT_ACTIVE,
  },
  {
    value: PROJECT_STATUS.COMPLETED,
    label: I18N.STATUS_PROJECT_COMPLETED,
  },
  {
    value: PROJECT_STATUS.CANCELLED,
    label: I18N.STATUS_PROJECT_CANCELLED,
  },
];

export const INIT_STORAGE_STATUSES = [
  {
    value: PROJECT_STATUS.PLANNED,
    label: I18N.STATUS_PROJECT_PLANNED,
  },
  {
    value: PROJECT_STATUS.ACTIVE,
    label: I18N.STATUS_PROJECT_ACTIVE,
  },
  {
    value: PROJECT_STATUS.CLOSED,
    label: I18N.STATUS_PROJECT_CLOSED,
  },
];

export const PROJECT_SUPPLY_DEMAND_STATUSES = [
  {
    value: ProjectSupplyDemandType.SUPPLY,
    label: I18N.STATUS_SUPPLY,
  },
  {
    value: ProjectSupplyDemandType.DEMAND,
    label: I18N.STATUS_DEMAND,
  },
  {
    value: ProjectSupplyDemandType.SUPPLY_AND_DEMAND,
    label: I18N.STATUS_SUPPLY_AND_DEMAND,
  },
];

export const PROJECT_TRANSFER_OPTIONS = [
  {
    value: ProjectMassType.REUSED,
    label: I18N.PROJECT_MASS_REUSED_IN,
  },
  {
    value: ProjectMassType.INTERNAL_PROJECT,
    label: I18N.PROJECT_OTHER_PROJECT_IN_ROCKEXCHANGE,
  },
  {
    value: ProjectMassType.STORAGE,
    label: I18N.PROJECT_STORAGE_FACILITY,
  },
  {
    value: ProjectMassType.DEPOT,
    label: I18N.PROJECT_RECYCLING_FACILITY,
  },
  {
    value: ProjectMassType.EXTERNAL_PROJECT,
    label: I18N.PROJECT_EXTERNAL_PROJECT,
  },
  {
    value: ProjectMassType.OTHER,
    label: I18N.PROJECT_OTHER_ADDRESS,
  },
];

export const INIT_PROJECT_MASS: ProjectMass = {
  type: undefined,
  origin: undefined,
  weight: null,
  location: INIT_LOCATION,
};
