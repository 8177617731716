// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import {
  CLUSTER_LAYER_PREFIX,
  DEFAULT_ZOOM_LEVEL,
  DEFAULT_ZOOM_LEVEL_MOBILE,
  MAP_CENTER,
  MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK,
  MAP_LAYER_DATA_MINERAL_EXTRACTION,
  MAP_LAYER_DATA_POLLUTION,
  MAP_MAX_BOUNDS,
  MIN_ZOOM_LEVEL,
} from '@/constants/map.constants';
import { onFeatureClick } from '@components/commons/Map/MapUtils/commonEvents';
import { useResizeObserver } from '@utils/resizeObserver';
import { MapMouseEvent } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import {
  loadMassLayerGravelAndHardRock,
  loadMassLayerMineralExtraction,
  loadMassLayerPollution,
} from './dataLayers/massLayer';
import { MAP_SEARCH_MODE, MAP_SOURCE, MAP_TYPE } from '@/enums/map.enum';
import { HUB_CATEGORY_TYPE } from '@/enums/hubCategory.enum';
import { MAP_LAYER_FILTER } from '@/enums/map.enum';
import { useStore } from '@/RootStoreProvider';
import {
  DEFAULT_MASS_LAYOUT_ICON_IMAGE,
  DEFAULT_MASS_LAYOUT_ICON_SIZE,
  DEFAULT_MASS_LAYOUT_TEXT_FIELD,
  DEFAULT_MASS_LAYOUT_TEXT_OFFSET,
} from '@components/commons/Map/dataLayers/massLayer';
import { MASS_TYPE } from '@enums/mass.enum';
import { getDir } from '@components/settings/TravelPlanner/compute';
import { theme } from '@/themes/MolunderTheme';
import { useMediaQuery } from '@mui/material';
import { ScreenWidth } from '@enums/screen.enum';
import { retrieveFromStorage } from '@utils/storage.util';

interface MapboxMapProps {
  initialOptions?: Omit<mapboxgl.MapboxOptions, 'container'>;
  onCreated?(map: mapboxgl.Map): void;
  onLoaded?(map: mapboxgl.Map): void;
  onMove?(map: mapboxgl.Map): void;
  onClickCallback?(feature: any): void;
  onRemoved?(): void;
  initialPosition?: any[];
  minZoom?: number;
  massType?: MAP_TYPE | undefined;
  filterLayers?: { id: MAP_LAYER_FILTER; checked: boolean }[];
  onHubFilter?(map: mapboxgl.Map): void;
}

function MapboxMap({
  initialOptions = {},
  onCreated,
  onLoaded,
  onRemoved,
  onMove,
  initialPosition,
  minZoom,
  onClickCallback,
  massType,
  filterLayers,
  onHubFilter,
}: MapboxMapProps) {
  const { languageStore, mapLayerStore, userStore, massStore, mapStore } =
    useStore();
  const isWeb = useMediaQuery(
    theme.breakpoints.up(
      retrieveFromStorage('mobileView') === 'true'
        ? ScreenWidth.MEDIUM
        : ScreenWidth.SMALL
    )
  );
  const [map, setMap] = useState<mapboxgl.Map>();
  const mapNode = useRef(null);
  let mapboxMap: mapboxgl.Map;
  const mapContainerResizeCallback = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const focusLocation = userStore.currentUser.focusLocation
    ? [
        userStore.currentUser.focusLocation.longitude,
        userStore.currentUser.focusLocation.latitude,
      ]
    : userStore.currentUser.location
    ? [
        userStore.currentUser.location.longitude,
        userStore.currentUser.location.latitude,
      ]
    : MAP_CENTER;
  useResizeObserver(mapNode, mapContainerResizeCallback);

  useEffect(() => {
    const node = mapNode.current;
    if (typeof window === 'undefined' || node === null) return;

    let center = initialPosition ?? focusLocation;
    if (center[0] == 0 && center[1] == 0) center = MAP_CENTER;

    mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      style: 'mapbox://styles/henrikmaa/ckvve8b403vqq15pb4cwak5dg',
      center: center, // starting position [lng, lat]
      zoom: isWeb ? DEFAULT_ZOOM_LEVEL : DEFAULT_ZOOM_LEVEL_MOBILE,
      minZoom: minZoom ?? 1, //6.202882378398238,
      trackResize: true,
      maxBounds: MAP_MAX_BOUNDS,
      hash: true,
      ...initialOptions,
    });

    setMap(mapboxMap);

    if (onCreated) onCreated(mapboxMap);
    if (onMove) mapboxMap.on('move', () => onMove(mapboxMap));

    mapboxMap.on('load', () => {
      if (onLoaded) {
        onLoaded(mapboxMap);
      }
    });

    if (onClickCallback) {
      Object.values(HUB_CATEGORY_TYPE).forEach((category): void => {
        onFeatureClick(category, mapboxMap, (event: MapMouseEvent) =>
          onClickCallback(event)
        );
        // onFeatureClick(
        //   `cluster_${category}`,
        //   mapboxMap,
        //   (event: MapMouseEvent) => onClickCallback(event)
        // );
      });
      Object.values(MASS_TYPE).forEach((mass): void =>
        onFeatureClick(mass, mapboxMap, (event: MapMouseEvent) =>
          onClickCallback(event)
        )
      );
    }

    return () => {
      mapLayerStore.clearAllLayers();
      setMap(undefined);
      if (onRemoved) onRemoved();
    };
  }, [
    languageStore.activeLanguage,
    massStore.currentSupply,
    massStore.currentRequest,
  ]);

  useEffect(() => {
    if (map) {
      loadMassLayerGravelAndHardRock(
        map,
        MAP_LAYER_DATA_GRAVEL_AND_HARD_ROCK.layerId,
        mapLayerStore.gravelAndHardRockLayers
      );
    }
  }, [mapLayerStore.gravelAndHardRockLayers]);

  useEffect(() => {
    if (map) {
      loadMassLayerPollution(
        map,
        MAP_LAYER_DATA_POLLUTION.layerId,
        mapLayerStore.contaminationLayers
      );
    }
  }, [mapLayerStore.contaminationLayers]);

  useEffect(() => {
    if (map) {
      loadMassLayerMineralExtraction(
        map,
        MAP_LAYER_DATA_MINERAL_EXTRACTION.layerId,
        mapLayerStore.mineralExtractionLayers
      );
    }
  }, [mapLayerStore.mineralExtractionLayers]);

  useEffect(() => {
    if (map) {
      filterLayers.map((layer) => {
        const clusteredLayerId = `${CLUSTER_LAYER_PREFIX}${layer.id}`;
        const clusteredCountLayerId = `${CLUSTER_LAYER_PREFIX}${layer.id}-count`;

        if (map.getLayer(clusteredLayerId)) {
          map.setLayoutProperty(
            clusteredLayerId,
            'visibility',
            layer.checked ? 'visible' : 'none'
          );
        }

        if (map.getLayer(clusteredCountLayerId)) {
          map.setLayoutProperty(
            clusteredCountLayerId,
            'visibility',
            layer.checked ? 'visible' : 'none'
          );
        }

        if (map.getLayer(layer.id)) {
          map.setLayoutProperty(
            layer.id,
            'visibility',
            layer.checked ? 'visible' : 'none'
          );
        }
      });
    }
  }, [filterLayers]);

  useEffect(() => {
    if (map) {
      if (mapStore.mapCenter) {
        map.setZoom(DEFAULT_ZOOM_LEVEL);
        map.flyTo({
          center: mapStore.mapCenter,
        });
      } else {
        map.setZoom(MIN_ZOOM_LEVEL);
      }
    }
  }, [mapStore.mapCenter]);

  useEffect(() => {
    if (map) {
      if (mapStore.displayedRoute) {
        getDir(
          map,
          mapStore.displayedRoute[0],
          mapStore.displayedRoute[1],
          undefined,
          undefined,
          undefined
        );
      }
    }
  }, [mapStore.displayedRoute]);

  useEffect(() => {
    if (!map) return;

    const style = map.getStyle();
    const isSearching = [
      MAP_SEARCH_MODE.SUPPLY,
      MAP_SEARCH_MODE.REQUEST,
      MAP_SEARCH_MODE.EXCHANGE,
    ].includes(mapStore.searchMode);

    const filterLocationIds = massStore.exchangeSearchResult.map(
      (exchange) => exchange.locationId
    );

    map.setStyle(style);
    // Remove number badge when searching
    if (isSearching) {
      const iconImage =
        mapStore.searchMode === MAP_SEARCH_MODE.REQUEST
          ? 'request'
          : 'location';
      map.setLayoutProperty(mapStore.searchMode, 'icon-image', iconImage);
    } else {
      map.setLayoutProperty(
        massType,
        'icon-image',
        DEFAULT_MASS_LAYOUT_ICON_IMAGE
      );
      map.setLayoutProperty(
        massType,
        'icon-size',
        DEFAULT_MASS_LAYOUT_ICON_SIZE
      );
      map.setLayoutProperty(
        massType,
        'text-field',
        DEFAULT_MASS_LAYOUT_TEXT_FIELD
      );
      map.setLayoutProperty(
        massType,
        'text-offset',
        DEFAULT_MASS_LAYOUT_TEXT_OFFSET
      );
    }

    filterLayers
      .filter((layer) => !!layer.checked)
      .forEach((layer) => {
        map.setFilter(
          layer.id,
          isSearching
            ? ['in', ['get', 'locationId'], ['literal', filterLocationIds]]
            : null
        );
      });

    if (map.getLayer('stretch') && !isSearching) {
      map.removeLayer('stretch');
    }
    if (map.getLayer(`${CLUSTER_LAYER_PREFIX}mix`)) {
      map.setLayoutProperty(
        `${CLUSTER_LAYER_PREFIX}mix`,
        'visibility',
        isSearching ? 'none' : 'visible'
      );
    }

    if (map.getLayer(`${CLUSTER_LAYER_PREFIX}mix_count`)) {
      map.setLayoutProperty(
        `${CLUSTER_LAYER_PREFIX}mix_count`,
        'text-size',
        isSearching ? 0 : 16
      );
      map.setLayoutProperty(
        `${CLUSTER_LAYER_PREFIX}mix_count`,
        'visibility',
        isSearching ? 'none' : 'visible'
      );
    }
  }, [mapStore.searchMode, massStore.exchangeSearchResult]);

  useEffect(() => {
    if (map && mapStore.filteredHubs.length) {
      onHubFilter(map);
    }
  }, [mapStore.filteredHubs]);

  return (
    <>
      <div
        ref={mapNode}
        style={{ width: '100%', height: '100%', display: 'flex' }}
      ></div>
    </>
  );
}

export default observer(MapboxMap);
