import { Box } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { IconButton, useMediaQuery } from '@mui/material';
import MaterialSelection from './FilterSelectMaterial';
import Close from '@mui/icons-material/CloseOutlined';
import { SIDE_BAR_WIDTH } from '@/constants/css.constants';
import {
  FilterLocation,
  FilterMaterialCondition,
  FilterPrice,
  FilterVolume,
} from '@/stores/marketplace-filter.store';
import VolumeSelection from './FilterSelectVolume';
import PriceSelection from './FilterSelectPrice';
import MaterialConditionSelection from './FilterSelectMaterialCondition';
import FilterSelectLocation from './FilterSelectLocation';
import { theme } from '@/themes/MolunderTheme';
import { useStore } from '@/RootStoreProvider';
import { ScreenWidth } from '@enums/screen.enum';
import { retrieveFromStorage } from '@utils/storage.util';

export enum FILTER_MENU_ITEM_TYPE {
  material,
  volume,
  price,
  location,
  materialCondition,
}

export type FilterMenuItem = {
  name: string;
  type: FILTER_MENU_ITEM_TYPE;
};

const menuItems = [
  {
    name: 'Material Type',
    type: FILTER_MENU_ITEM_TYPE.material,
  },
  {
    name: 'Volume',
    type: FILTER_MENU_ITEM_TYPE.volume,
  },
  {
    name: 'Price',
    type: FILTER_MENU_ITEM_TYPE.price,
  },
  { name: 'Location', type: FILTER_MENU_ITEM_TYPE.location },
  { name: 'Material Condition', type: FILTER_MENU_ITEM_TYPE.materialCondition },
];

interface ComponentProps {
  isSelectionActive: boolean;
  setSelectionActive: (selected: boolean) => void;
  selectedMaterials: Set<string>;
  volume: FilterVolume;
  location: FilterLocation;
  price: FilterPrice;
  materialCondition: FilterMaterialCondition;
  onUpdateCount: () => void;
  isSupply: boolean;
}

const FilterSelection = (props: ComponentProps) => {
  const {
    setSelectionActive,
    selectedMaterials,
    volume,
    price,
    location,
    materialCondition,
    onUpdateCount,
    isSupply,
    isSelectionActive,
  } = props;

  const { massStore } = useStore();

  const isWeb = useMediaQuery(
    theme.breakpoints.up(
      retrieveFromStorage('mobileView') === 'true'
        ? ScreenWidth.MEDIUM
        : ScreenWidth.SMALL
    ),
    {
      defaultMatches: true,
    }
  );

  const [selection, setSelection] = useState<FILTER_MENU_ITEM_TYPE | null>(
    null
  );

  useEffect(() => {
    if (!isSelectionActive && selection !== null) {
      setSelection(null);
    }
  }, [isSelectionActive]);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const resetFilter = () => {
    setSelection(null);
    setSelectionActive(false);
    onUpdateCount();
  };

  const getAvailableMaterials = (): Set<string> => {
    const materials = new Set<string>();

    if (isSupply) {
      const supplyList = massStore.supplyList;

      supplyList.forEach((supply) => {
        materials.add(supply.materialName);
      });
    } else {
      const requestList = massStore.requestList;

      requestList.forEach((request) => {
        materials.add(request.materialName);
      });
    }

    return materials;
  };

  const getSelectionTypeCount = (type: FILTER_MENU_ITEM_TYPE): number => {
    switch (type) {
      case FILTER_MENU_ITEM_TYPE.material:
        return selectedMaterials.size;
      case FILTER_MENU_ITEM_TYPE.volume: {
        let count = 0;
        if (volume.min !== undefined) count++;

        if (volume.max !== undefined) count++;

        return count;
      }
      case FILTER_MENU_ITEM_TYPE.price: {
        let count = 0;
        if (price.min !== undefined) count++;

        if (price.max !== undefined) count++;

        return count;
      }
      case FILTER_MENU_ITEM_TYPE.location: {
        let count = 0;
        if (location.radius !== undefined) count++;

        if (location.location !== undefined) count++;

        return count;
      }

      case FILTER_MENU_ITEM_TYPE.materialCondition: {
        let count = 0;
        if (materialCondition.hasLosAngelesVal) count++;

        if (materialCondition.hasMicroDevalVal) count++;

        return count;
      }
    }
  };

  const getSelection = (): any => {
    switch (selection) {
      case FILTER_MENU_ITEM_TYPE.material:
        return (
          <MaterialSelection
            onReturn={resetFilter}
            selectedMaterials={selectedMaterials}
            materials={getAvailableMaterials()}
          />
        );
      case FILTER_MENU_ITEM_TYPE.volume:
        return <VolumeSelection onReturn={resetFilter} volume={volume} />;

      case FILTER_MENU_ITEM_TYPE.price:
        return <PriceSelection onReturn={resetFilter} price={price} />;

      case FILTER_MENU_ITEM_TYPE.location:
        return (
          <FilterSelectLocation
            onReturn={resetFilter}
            filterLocation={location}
          />
        );

      case FILTER_MENU_ITEM_TYPE.materialCondition:
        return (
          <MaterialConditionSelection
            onReturn={resetFilter}
            materialCondition={materialCondition}
          />
        );
      default:
        return <div>None</div>;
    }
  };

  const getSelectedValuesForMenuItem = (
    type: FILTER_MENU_ITEM_TYPE
  ): JSX.Element[] => {
    const getSelectedMenuItem = (name: string, onRemove: () => void) => {
      return (
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: '8px',
            marginRight: '8px',
            marginBottom: '8px',
            border: '1px solid #0163B3',
            borderRadius: '50px',
          }}
        >
          <text
            style={{
              fontSize: '14px',
              color: '#0163B3',
            }}
          >
            {name}
          </text>
          <IconButton
            size='small'
            onClick={() => {
              onRemove();
              forceUpdate();
            }}
          >
            <Close
              fontSize='small'
              sx={{
                color: '#0163B3',
              }}
            />
          </IconButton>
        </Box>
      );
    };

    const selections: JSX.Element[] = [];

    switch (type) {
      case FILTER_MENU_ITEM_TYPE.material: {
        selectedMaterials.forEach((material) =>
          selections.push(
            getSelectedMenuItem(material, () => {
              selectedMaterials.delete(material);
              props.onUpdateCount();
            })
          )
        );
        break;
      }
      case FILTER_MENU_ITEM_TYPE.volume: {
        if (volume.min !== undefined) {
          selections.push(
            getSelectedMenuItem(`Min: ${volume.min}`, () => {
              volume.min = undefined;
              props.onUpdateCount();
            })
          );
        }
        if (volume.max !== undefined) {
          selections.push(
            getSelectedMenuItem(`Max: ${volume.max}`, () => {
              volume.max = undefined;
              props.onUpdateCount();
            })
          );
        }
        break;
      }
      case FILTER_MENU_ITEM_TYPE.price: {
        if (price.min !== undefined) {
          selections.push(
            getSelectedMenuItem(`Min: ${price.min}`, () => {
              price.min = undefined;
              props.onUpdateCount();
            })
          );
        }
        if (price.max !== undefined) {
          selections.push(
            getSelectedMenuItem(`Max: ${price.max}`, () => {
              price.max = undefined;
              props.onUpdateCount();
            })
          );
        }
        break;
      }
      case FILTER_MENU_ITEM_TYPE.location: {
        if (location.location !== undefined) {
          selections.push(
            getSelectedMenuItem(
              `Location: ${location.location.place_name}`,
              () => {
                location.location = undefined;
                location.radius = undefined;

                props.onUpdateCount();
              }
            )
          );
        }
        if (location.radius !== undefined) {
          selections.push(
            getSelectedMenuItem(`Radius: ${location.radius}`, () => {
              location.radius = undefined;
              location.location = undefined;

              props.onUpdateCount();
            })
          );
        }
        break;
      }
      case FILTER_MENU_ITEM_TYPE.materialCondition: {
        if (materialCondition.hasLosAngelesVal) {
          selections.push(
            getSelectedMenuItem(`Has Los Angeles Value`, () => {
              materialCondition.hasLosAngelesVal = false;
              props.onUpdateCount();
            })
          );
        }
        if (materialCondition.hasMicroDevalVal) {
          selections.push(
            getSelectedMenuItem(`Has Micro Deval Value`, () => {
              materialCondition.hasMicroDevalVal = false;
              props.onUpdateCount();
            })
          );
        }
        break;
      }
    }

    return selections;
  };

  const getSelectionName = (type: FILTER_MENU_ITEM_TYPE): string => {
    switch (type) {
      case FILTER_MENU_ITEM_TYPE.material:
        return 'material';
      case FILTER_MENU_ITEM_TYPE.volume:
        return 'volum';
      case FILTER_MENU_ITEM_TYPE.price:
        return 'pris';
      case FILTER_MENU_ITEM_TYPE.location:
        return 'lokasjon';
      case FILTER_MENU_ITEM_TYPE.materialCondition:
        return 'materialtilstand';
    }
  };

  return (
    <>
      {selection === null ? (
        <Box
          sx={{
            padding: '0px 16px',
            minWidth: isWeb ? null : `${SIDE_BAR_WIDTH}px`,
          }}
        >
          {menuItems.map((item, index) => (
            <>
              <Box
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  height: '56px',
                  backgroundColor: '#fff',
                  padding: '0 16px',
                  marginBottom: '2px',
                }}
                onClick={() => {
                  setSelection(item.type);
                  setSelectionActive(true);
                }}
              >
                <text>{item.name}</text>
                <IconButton>
                  <KeyboardArrowRightOutlinedIcon
                    fontSize='small'
                    sx={{
                      color:
                        getSelectionTypeCount(item.type) > 0 ? '#0163B3' : '',
                    }}
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  padding: '0px',
                  maxWidth: '100%',
                  paddingTop:
                    index > 0 && getSelectionTypeCount(item.type) > 0
                      ? '8px'
                      : '',
                }}
              >
                {getSelectedValuesForMenuItem(item.type).map((value) => value)}
              </Box>
            </>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            padding: '0px 16px',
            minWidth: isWeb ? null : `${SIDE_BAR_WIDTH}px`,
          }}
        >
          <text
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '16px 0px',
            }}
          >
            Velg {getSelectionName(selection)}
          </text>
          {getSelection()}
        </Box>
      )}
    </>
  );
};

export default FilterSelection;
